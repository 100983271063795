body {
  margin: 0;
  background: #eae7e6;
  transition: all 0.5s ease-out;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #ff6384 #fff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #ff6384;
  border-radius: 0px;
}

.bordaDirEsqradius {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.table {
  margin-bottom: 0 !important;
}

.table {
  overflow: auto;
  table-layout: auto !important;
}

.containerFlexBox {
  display: flex;
  justify-content: space-between;
}

.tabela-largura-min-max {
  max-width: 1020px;
  min-width: 1020px;
}

.wrapper {
  width: auto;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 5px 50px -15px rgba(0, 0, 0, 0.1);
}

.wrapper-table {
  position: relative;
  width: auto;
  margin-top: 18px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 5px 50px -15px rgba(0, 0, 0, 0.1);
}

.wrapper-total {
  width: auto;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 5px 50px -15px rgba(0, 0, 0, 0.1);
}

.progress {
  padding: 20;
  width: auto;
}

.wpp-marfin {
  margin-top: 15px;
}

.margin-top-bottom {
  margin-top: 20px;
  margin-bottom: 20px;
}

.wrapper-body {
  width: auto;

  padding-bottom: 15px;
}

.colMetasGerais1 {
  padding-top: 28px;
  padding-left: 28px;
}

.colMetasGerais2 {
  margin-right: 15px;
}

.wrapper-title {
  background: rgb(231, 231, 231);
  width: max-content;
  padding: 0 10px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2),
    3px 11px 33px -33px rgba(0, 0, 0, 0.2);
}

.divPadding14px {
  padding: 14px;
}

.divPadding22px {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.back-color-principal:hover {
  background-color: #fc68a2 !important;
  border: solid 1px #f6398d;
}

.container-app {
  display: flex;
  min-height: 100vh;
}

.alerta-login {
  display: inline;
  margin: auto;
}

.alerta-login p {
  padding: 20px;
}

.showBackgroundLoading {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #eae7e6;
  justify-content: center;
  display: flex;
  transition: all 0.5s ease-out;
}

.buttonBase {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: end;
}

.containerButtoonLoja {
  display: flex;
  justify-content: end;
}

.containerInfo {
  display: table-cell;
  justify-content: initial;
}

.fInfo {
  background-color: #e8cdcda6;
  border-color: #e8cdcda6;
  color: #72777d;
  border-radius: 0;
  padding: 0.34rem !important;
  margin: 0 !important;
}

.title-position {
  position: absolute;
  top: 15px;
  left: 10px;
}

.radius0 {
  border-radius: 8px;
  border-color: #fc68a2 !important;
}

.radius0:hover {
  color: #fff;
  background-color: #fc68a2 !important;
}

.radius0Active {
  background-color: #fc68a2 !important;
}

.radius01 {
  border-radius: 8px;
  border-color: #fc68a2 !important;
}

.radius01:hover {
  color: #fff;
  background-color: #fc68a2 !important;
}

.btt-active {
  border-color: #fc68a2 !important;
  background-color: #fc68a2 !important;
}

.formSelectBase {
  color: #fc68a2;
  border-color: #fc68a2;
  width: 10rem;
}

.bttSelectBase {
  color: #fc68a2;
}

.fInfoNew {
  background-color: transparent !important;
  border: none;
  white-space: nowrap;
  color: #fc68a2;
  margin-top: 10px;
}

.containerFlexBoxRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container-mes {
  padding: 15px 15px 0 0;
}

.bttBaseColor {
  background-color: #909ca3;
  border-color: #909ca3;
}

.containerTableCell {
  display: table-cell;
}

.containerTable {
  display: table;
}

.container-conteudo {
  flex: 1;
  padding: 0;
  margin: 0;
  border: 0px;
  background-color: #fff;
  max-width: 95%;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pro-sidebar-header {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
}

.imagem-logo {
  max-width: 80%;
  cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item,
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #808080;
  padding-left: 8px;
}

.pro-sidebar > .pro-sidebar-inner {
  background: #ffffff;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #fff;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: #fff;
}

.container-title {
  width: 50%;
  text-align: initial;
  padding: 10px;
}

.container-title-new {
  width: 50%;
  text-align: initial;
  padding: 10px;
  white-space: nowrap;
}

.container-metas {
  padding: 10px;
}

.container-controles-periodo {
  justify-content: space-evenly;
  max-width: 100%;
  /* width: 100vw; */
  padding: 5px;
}

.container-controles-periodo small {
  padding: 5px;
  border: solid 1px #808080;
  border-radius: 10px;
  cursor: pointer;
}

.container-controles-periodo small:hover {
  background: #dad8d8;
}

.container-controles-periodo small.selected {
  padding: 5px;
  border: solid 1px #808080;
  border-radius: 10px;
  cursor: pointer;
  background: #808080;
  color: #fff;
}

.title-periodo {
  margin: 0;
  padding: 5px;
  text-align: center;
}

.container-grafico-periodo {
  padding: 15px;
  max-width: 590px;
}

.rdt_TableCol {
  color: rgb(0, 0, 0) !important;
}

.container-metas-interno-inferior.metas-vendedor .rdt_TableCol {
  color: #000 !important;
}

.container-metas-interno-inferior.metas-vendedor .rdt_TableHeadRow {
  background-color: #f3a8a8 !important;
  min-height: 40px;
}

.rdt_TableHeadRow {
  /* background-color: #808080 !important; */
  color: #000 !important;
}

.jkEUmm:hover {
  color: #000000 !important;
}

.container-metas-interno-inferior {
  border: solid 1px #808080;
}

.container-metas-interno-inferior.metas-vendedor {
  border: none;
}

.container-metas-interno-inferior-tabelas-globais {
  /* border: solid 1px #808080; */
  margin-top: 10px;
}

.table-resume-header {
  border-top: solid 1px #808080;
  border-bottom: solid 1px #808080;
}

.container-grafico-barra-linha {
  height: 100%;
}

.container-grafico-meio-circulo {
  border: solid 1px #808080;
  text-align: center;
}

.container-metas-interno-superior-metas {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.container-metas-interno-superior {
  display: inline-flex;
  width: 100%;
}

.container-metas-interno-superior-esquerda {
  flex: 2;
  width: 100%;
}

.container-metas-interno-superior-direita {
  flex: 1;
  width: 40%;
}

.container-metas-barra {
  flex: 1;
  display: grid;
}

.container-botao-filtrar-bases {
  text-align: center;
}

.container-metas-interno-superior-direita.container-metas-interno-superior-direita-vendedor {
  margin-top: 10px;
}

.container-nomesLojas {
  display: grid;
}

.container-metas-interno-superior-esquerda-vendas {
  flex: 5;
  max-width: 75%;
  border: solid 1px #808080;
  margin-right: 10px;
}

.container-grafico-desconto {
  border: solid 1px #808080;
  margin-left: 10px;
  display: flex;
  flex: 1;
  height: 100%;
}

.body {
  min-height: 80vh;
}

.container-metas-interno-superior-direita-vendas {
  flex: 1;
}

.container-metas-interno-superior-esquerda-vendedores {
  flex: 5;
}

.container-metas-interno-superior-direita-vendedores {
  flex: 2;
}

.container-footer-grafico-meio-circulo {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.container-filtros {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.legenda-central-grafico-meio-circulo {
  font-size: 30px;
  margin-top: -40px;
}

.container-aviso-dados-indisponiveis {
  display: flex;
  text-align: center;
  padding: 15px;
  margin: auto;
}

.container-aviso-dados-indisponiveis span {
  color: #f41068;
  font-size: 20px;
}

.container-loading {
  text-align: center;
}

.container-loading-permissoes {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
}

.component-loading {
  margin: auto;
}

._loading_overlay_wrapper {
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
}

.btn-flat {
  background-color: purple;
  color: white;
}

.container-icon-menu {
  padding: 15px;
  cursor: pointer;
}

.container-nomes-lojas.container-nomes-lojas-vendedor {
  display: flex;
  /* text-align: center; */
  justify-content: center;
}

.container-nomes-lojas.container-nomes-lojas-vendedor div div:not(.no-border) {
  width: 90%;
  padding: 10px;
  border: solid 1px #808080;
  margin: 2px;
  border-radius: 0px;
  cursor: pointer;
}

.container-nomes-lojas div {
  display: flex;
  justify-content: center;
}

.container-nomes-lojas div div:not(.no-border) {
  width: 90%;
  padding: 10px;
  border: solid 1px #808080;
  margin: 2px;
  border-radius: 10px;
  cursor: pointer;
}

.container-nomes-lojas p {
  padding: 10px;
  border: solid 1px #808080;
  margin: 2px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
}

.container-nomes-lojas p.selected {
  padding: 10px;
  border: solid 1px #808080;
  border-radius: 5px;
  cursor: pointer;
  background: #808080;
  color: #fff;
}

.container-grafico-meio-circulo,
.container-tabela-nomes-lojas p {
  margin: 0;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  font-weight: bold;
}

.container-tabela-nomes-lojas {
  border-bottom: solid 1px #666;
  border-bottom-style: dotted;
  text-align: center;
}

.lojaSelecionada {
  background: #808080;
  color: #fff;
}

.button-nome-loja {
  width: fit-content;
}

.d-block {
  display: block !important;
}

.container-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #eee;
}

.form-login {
  width: 400px;
  background: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-login {
  width: 100%;
  margin: 10px 0 40px;
}

.container-grafico-tabela .img-login {
  max-width: 100px;
  border-radius: 50%;
}

.p-login {
  color: #ff3333;
  margin-bottom: 15px;
  border: 1px solid #ff3333;
  padding: 10px;
  width: 100%;
  text-align: center;
}

.input-login {
  flex: 1 1;
  height: 46px;
  margin-bottom: 15px;
  padding: 15px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
}

.navBarClass {
  box-shadow: 1px 1px 1px rgba(173, 173, 173, 0.2);
  background: #ffc2da;
  padding-top: 0px;
  padding-bottom: 0px;
}

.botao-login {
  color: #fff;
  font-size: 16px;
  background: #f81d80;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}

.hr-login {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  content: none;
}

.linkMenu {
  padding-left: 24px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  color: #464a53;
}

.pro-sidebar .pro-menu a:hover {
  color: #76838f;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #76838f;
}

.titulo-menu {
  padding-left: 8px;
  font-size: 0.75rem !important;
}

.container-menu-item {
  padding-left: 8px;
}

.menuItemSemOpcoes a {
  color: #464a53 !important;
}

.iconeItensSemOpcoes {
  position: absolute;
  right: 20px;
  color: #adadad;
}

.pro-sidebar.collapsed .iconeItensSemOpcoes {
  visibility: hidden;
}

.container-interno-filtros-esquerda {
  /* width: 60%;
    max-width: 60%; */
  /* background-color: #ff0000; */
  border: solid 1px #808080;
  height: 100%;
  width: 100%;
  margin-right: 10px;
}

.container-interno-filtros-direita {
  width: 40%;
  max-width: 40%;
  /* background-color: #ff00ff; */
  border: solid 1px #808080;
}

.titulo-tipo-filtro {
  color: #f6398d;
  /* width: 100%; */
  margin: 0;
  padding: 2px;
  text-align: center;
  font-size: 18px;
}

.container-title h1 {
  color: #f6398d;
}

.container-title-new h1 {
  color: #fc68a2;
}

.container-filtro-input-periodo-titulo {
  display: flex;
  justify-content: space-around;
}

.container-filtro-input-periodo-inputs {
  display: flex;
  justify-content: space-around;
  padding: 2px 5px;
}

p {
  margin: 0;
  padding: 0;
}

.linha-divisoria {
  border: solid 1px #d8d4d4;
  margin: 10px 5% 10px 5%;
}

.container-filtro-anos p,
.container-filtro-mes p {
  width: fit-content;
  border: solid 1px #808080;
  border-radius: 5px;
  padding: 5px;
  margin: 0 2px 5px;
  cursor: pointer;
}

.container-filtro-mes p.selected {
  padding: 5px;
  border: solid 1px #808080;
  border-radius: 5px;
  cursor: pointer;
  background: #808080;
  color: #fff;
}

.container-filtro-anos p.selected {
  padding: 5px;
  border: solid 1px #808080;
  border-radius: 5px;
  cursor: pointer;
  background: #808080;
  color: #fff;
}

.container-filtro-anos,
.container-filtro-mes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.container-botao-filtrar {
  margin: 5px;
}

.container-botao-voltar {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.botao-voltar-active {
  background-color: #fc68a2 !important;
  border-radius: 8px;
  border: none;
  padding: 15px;
  align-self: flex-end;
}

.botao-voltar {
  background-color: #fc68a2;
  border-radius: 8px;
  border: none;
  padding: 15px;
  align-self: flex-end;
}

.Content-buttons-filtrar-hoje {
  display: flex;
  justify-content: center;
}

.botao-filtrar-input-data {
  width: 100%;
  margin: 5px;
  background: #fc68a2 !important;
  border: none;
  cursor: pointer;
  color: #ffff;
  min-width: 60px;
  border-radius: 8px;
}

.botao-filtrar-input-data-hoje {
  width: 100%;
  margin: 5px;
  background: #5b8bfc;
  cursor: pointer;
  color: #fff;
  border-radius: 8px;
  border: none;
  min-width: 60px;
}

.botao-filtrar-input-data-hoje-active {
  background-color: #5b8bfc !important;
  border-radius: 8px;
  border: none;
}

.filtros-ativos-grafico {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 5;
  margin-right: 10px;
}

.filtros-ativos-grafico-desempenho {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 3;
  margin-right: 10px;
}

.filtros-ativos-grafico p.resetar {
  text-decoration: underline;
  margin-right: 5px;
  cursor: pointer;
}

.filtros-ativos-grafico-desempenho p.resetar {
  text-decoration: underline;
  margin-right: 5px;
  cursor: pointer;
}

.filtros-ativos-grafico-aux {
  flex: 2;
}

.container-filtros-ativos-grafico {
  justify-content: center;
  display: flex;
  margin-right: 10px;
}

.container-grafico-globais {
  flex: 3;
}

.container-grafico-globais-aux {
  flex: 2;
}

.container-externo-grafico-globais {
  display: flex;
}

.container-filtros-ativos-grafico-desempenho {
  display: flex;
  margin-right: 10px;
  margin-top: 15px;
}

.container-metas-interno-inferior.metas-vendedor
  .container-grafico-tabela
  .rdt_TableCol_Sortable {
  text-align: right;
  font-size: 18px;
}

.scroll-horizental-histo {
  overflow-x: scroll;
  max-width: 93%;
}

.container-grafico-tabela {
  margin-bottom: 10px;
}

.container-grafico-tabela .rdt_Table {
  border-left: none;
  border-bottom: none;
  border-right: none;
}

.table-subHeader .rdt_Table {
  border: none !important;
}

.table-subHeader .rdt_TableRow {
  background: #fff;
}

.container-metas-interno-inferior.metas-vendedor .container-grafico-tabela {
  display: flex;
}

.wpp-vendedor {
  padding-bottom: 0;
  margin-bottom: 15px;
}

.alert-filterloja {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.div-nome-funcionario {
  min-height: 41px;
  border: solid 1px #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.div-imagem-funcionario {
  margin: 0px 5px;
}

.container-metas-interno-inferior.metas-vendedor
  .container-grafico-tabela
  .rdt_TableCell {
}

.aviso-nao-logado {
  font-size: 25px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 10%;
}

.container-centers {
  text-align: center;
}

.botaoTelaCheia {
  background: transparent;
  border: none;
  text-decoration: underline;
}

.container-nomes-lojas.container-nomes-lojas-vendas-global {
  display: flex;
  justify-content: center;
}

.container-nomes-lojas.container-nomes-lojas-vendas-global p {
  padding: 10px;
  border: solid 1px #808080;
  margin: 2px;
  border-radius: 0px;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
}

.container-botao-filtrar-metas {
  min-height: 24px;
}

.botao-filtrar-input-data-metas {
  margin-top: auto;
  padding-bottom: 0;
  min-height: 24px;
  text-align: center;
}

.container-filtro-metas {
  max-width: 300px;
  margin: auto;
  margin-top: 10px;
  min-height: 24px;
}

.container-filtro-input-periodo-metas {
  display: flex;
}

.container-legenda-tabelas {
  display: flex;
  margin: 10px 0;
}

.container-legenda-tabelas div {
  font-weight: bold;
  padding: 5px;
}

.titulo-tabela,
.titulo-subsessao {
  text-align: center;
  padding-top: 10px;
  color: #f6398d;
  font-size: 25px;
  font-weight: bold;
}

.container-tabela {
  border: solid 1px #808080;
  margin-top: 10px;
  margin-bottom: 10px;
}

.data-table-extensions {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.data-table-extensions-action {
  margin-left: auto;
}

.legenda-meta {
}

.indicador-vendedoras-abaixo-da-meta {
  background: #f00;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  text-align: center;
  margin-left: 5px;
  font-weight: bold;
}

.container-nomes-lojas-vendedor .svg-inline--fa.fa-w-16 {
  width: 12px;
  margin-left: 2px;
}

.legenda-flag-vendedora {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

.container-legenda-tabelas.legenda-tabela {
  margin-bottom: -51px;
  margin-top: 15px;
  padding-left: 22px;
}

.scroll-horizental-histo::-webkit-scrollbar {
  height: 5px;
}

.scroll-horizental-histo::-webkit-scrollbar-track-piece {
  background-color: #666666;
}

.container-speedometer-desconto {
  display: flex;
}

.container-app.container-webview-rn {
  margin: 0;
  padding: 0;
}

.container-app.container-webview-rn {
  margin: 0;
  padding: 0;
}

.container-webview-rn.container {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.container-webview-rn .container-conteudo {
  max-width: 100%;
  padding: 0;
  padding-top: 0;
  margin: 0;
}

.container-webview-rn .container-botao-filtrar,
.container-webview-rn .colMetasGerais2 {
  /* height: 60vh; */
  padding: 0;
}

.container-webview-rn .container-botao-filtrar-vendedor {
  height: 80vh;
  padding: 0;
  max-width: 100vw;
  overflow-x: scroll;
}

.container-webview-rn .chartResponsive {
  max-width: 100vw;
  overflow-x: scroll;
}

.container-webview-rn .container-metas-interno-superior-metas .container {
  padding: 0;
  position: relative;
}

.container-webview-rn .container-legenda-tabelas.legenda-tabela {
  margin-bottom: 0;
  font-size: 13px;
}

.container-webview-rn .legenda-meta {
  padding: 5px 5px;
}

.container-webview-rn .colMetasGerais1 {
  padding-left: 20px;
}

.container-vendedoras-abaixo-meta {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-vendedoras-abaixo-meta .item-vendedora {
  background: #e8cdcda6;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.container-vendedoras-abaixo-meta .item-vendedora .qtd {
  color: #f00;
}

.container-webview-rn .container-metas-app,
.container-webview-rn
  .container-metas-app
  .container-metas-interno-superior
  .container {
  padding: 0;
}

.container-webview-rn .container-metas-app .container-metas {
  padding: 0;
}

.container-webview-rn .div-nome-funcionario {
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  border: none;
  border-right: solid 1px #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  color: #000;
  font-size: 14px;
}

.container-webview-rn .container-metas-interno-inferior.metas-vendedor {
  margin-top: 15px;
  padding-bottom: 25px;
}

.container-webview-rn
  .container-metas-interno-inferior.metas-vendedor
  .container-grafico-tabela
  .rdt_TableCol_Sortable {
  text-align: right;
  font-size: 14px;
}

.container-webview-rn .container-lojas-disponiveis {
  margin-top: 10px;
}

@media (max-width: 1500px) {
  .container-conteudo {
    max-width: 100%;
  }

  .container-metas {
    max-width: 100%;
  }
}

.table-previsao-vendas {
  font-size: 0.75rem;
}

.classe-column-line-highlight-saldo {
  background-color: #ff0 !important;
}

.classe-column-highlight {
  background-color: #808080 !important;
  color: #fff !important;
  font-weight: bold;
}
.container-table-responsiva .react-bootstrap-table {
  overflow: scroll;
  max-height: 700px;
}

.tabelas-globais tr {
  border-bottom-color: #000;
}
/* table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  }
  
  table thead .dropup .caret, table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
  } */

.row-filter-produtos {
  justify-content: center;
}

.Content-buttons-filtrar-hoje.custom-position button {
  width: fit-content;
  margin-left: auto;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.cor-diamante {
  background-color: #6395ec !important;
  color: #fff !important;
}

.cor-ouro {
  background-color: #ffd700 !important;
  color: #000 !important;
}

.cor-meta {
  background-color: #008008 !important;
  color: #fff !important;
}

@media print {
  .nao-imprimir {
    display: none !important;
  }
}
